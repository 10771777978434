import { Link } from 'react-router-dom';
import Flower3D from './Flower3D.js';

const Header = (props) => {

  const { pathname, rotation, setSpinningFlowerDataUrl } = props;

  return (
    <div className={`App-header${ pathname === '/' || pathname === '/live' ? ' hidden' : ''}`} aria-hidden={ pathname === '/' || pathname === '/live' ? true : false}>
      <Link to="/" className="link--logo--main logo--spinning--nav nav--3d-flowers__button">
        <Flower3D canvasWrapperID="homeLink" rotation={rotation} setSpinningFlowerDataUrl={setSpinningFlowerDataUrl} />
        <span className="nav--3d-flowers__button__text">
          home
        </span>
      </Link>
    </div>
  )
};

export default Header;