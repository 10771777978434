const Logo = ({ fill, stroke, rotation }) => (
  <div
    className="logo__wrapper"
    style={{
      transform: `rotate(${rotation * 0.25}deg)`
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 313.32 313.32" width="313.32" height="313.32" className="logo">
      <path
        fill={fill}
        stroke={stroke}
        d="M261.1,104.44a52.22,52.22,0,0,1-52.22-52.22,52.22,52.22,0,0,0-104.44,0,52.22,52.22,0,0,1-52.22,52.22,52.22,52.22,0,0,0,0,104.44,52.22,52.22,0,0,1,52.22,52.22,52.22,52.22,0,0,0,104.44,0,52.22,52.22,0,0,1,52.22-52.22,52.22,52.22,0,0,0,0-104.44ZM156.66,208.88a52.22,52.22,0,1,1,52.22-52.22A52.22,52.22,0,0,1,156.66,208.88Z" />
    </svg>
  </div>
);

export default Logo;