import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import Background from '../Background.js';
import Malarquee from 'react-malarquee';
import { assetsPath } from '../cockpit.js';
import { motion, AnimatePresence } from 'framer-motion';
import { FlowerBlipBlopRotating } from '../components/BlipBlops.js';

const Marquee = ({ children, rate }) => {
  return (
    <div className="marquee__wrapper ">
      <Malarquee
        className="marquee__inner"
        rate={rate}
      >
        <span className="marquee__span uc">{children}</span>
      </Malarquee>
    </div>
  )
}

const LandingPageUIBottom = (props) => {

  const { isExpanded, handleClick, handleMouseEnter, handleMouseLeave, rotation, liveData, landingPageData, handleClickIntoBottomUIHeading, handleClickOutOfBottomUIHeading, windowDimensions } = props;
  const { info_title, info_text, image } = liveData;

  const handleSwipedUp = (e) => {
    handleClickIntoBottomUIHeading();
  }

  const handleSwipedDown = (e) => {
    handleClickOutOfBottomUIHeading();
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: (e) => handleSwipedUp(e),
    onSwipedDown: (e) => handleSwipedDown(e)
  });

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.4,
          delay: 0,
          ease: "easeInOut"
        }}
        className={`landing-page__ui--bottom${isExpanded === true ? ' landing-page__ui--bottom--expanded' : ''}`}
      >
        <div className="landing-page__ui--bottom__inner">
          <h2
            className="landing-page__ui--bottom__heading"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...swipeHandlers}
          >
            <Marquee rate={20}>
              {
                landingPageData.marquee_bottom ?
                  landingPageData.marquee_bottom[0] ?
                    <div className="marquee__inner">
                      {
                        landingPageData.marquee_bottom.map(
                          (item, i) => (
                            <span className={`marquee__span--${item.value.font}`} key={i}>{item.value.text}</span>
                          )
                        )
                      }
                    </div>
                  :
                    <span>A network for shaping softer digital futures</span>
                  :
                  <span>A network for shaping softer digital futures</span>
              }
            </Marquee>
          </h2>
          <AnimatePresence>
          {
            isExpanded === true ?
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.4,
                  delay: 0.4,
                  ease: "easeInOut"
                }}
                className="landing-page__ui--bottom__content"
                >
                <h3 className="landing-page__ui--bottom__subheading">
                  <Marquee rate={10}>
                    <span className="font--miu-miu-blur">{info_title}</span>
                  </Marquee>
                </h3>
                <div className="landing-page__info__wrapper">
                  <div className="landing-page__info__inner">
                    <div className="landing-page__info__text__wrapper">
                        <div className="landing-page__info__text">
                          <h3 className="bottom-ui__live__info__subtitle landing-page__info__subtitle">
                            <Link to="/live">
                              <span className="live__info__subtitle__span">Tune</span>
                              <span className="live__info__subtitle__span live__info__subtitle__flower__wrapper landing-page__info__subtitle__flower__wrapper">
                                <FlowerBlipBlopRotating fill="#000" stroke="transparent" rotation={rotation} />
                              </span>
                              <span className="live__info__subtitle__span">In</span>
                              <span className="live__info__subtitle__span live__info__subtitle__flower__wrapper landing-page__info__subtitle__flower__wrapper">
                                <FlowerBlipBlopRotating fill="#000" stroke="transparent" rotation={rotation} />
                              </span>
                              <span className="live__info__subtitle__span">Here</span>
                            </Link>
                          </h3>
                          <div className="landing-page__info__text__content">
                            {info_text ? parse(info_text) : ''}
                          </div>
                        </div>
                        <div
                          className="landing-page__info__image__wrapper"
                          style={{
                            backgroundImage: image && windowDimensions.windowWidth >= 500 ? `url(${assetsPath + image.path})` : null
                          }}
                        >
                          {
                            image && windowDimensions.windowWidth < 500 ?
                              <Link to="/live">
                                <img
                                  className="landing-page__info__image"
                                  src={assetsPath + image.path}
                                  alt={image.title ? image.title : 'Image illustrating the current livestream'}
                                />
                              </Link>
                              :
                              ''
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            :
              ''
            }
          </AnimatePresence>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const LandingPage = (props) => {

  const { windowDimensions, rotation, handleClickOutOfBottomUIHeading, landingPageData, landingPageDataIsSet, showSideMenu } = props;
  const { windowHeight } = windowDimensions;

  useEffect(() => {
    document.title = "Softer";
    showSideMenu();
  }, [showSideMenu]);

  return(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.9,
        delay: 0,
        ease: "easeInOut"
      }}
      className="view view--landing-page"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div
        onClick={handleClickOutOfBottomUIHeading}
        className="landing-page__background__wrapper"
        style={{
          height: windowHeight + 'px'
        }}
      >
        <Background
          windowDimensions={windowDimensions}
          rotation={rotation}
          landingPageData={landingPageData}
          landingPageDataIsSet={landingPageDataIsSet}
        />
      </div>
    </motion.div>
  )
};

export { LandingPage, LandingPageUIBottom };