
import { useEffect, useState } from 'react';
import { cockpitUrl } from '../cockpit.js';
import parse from 'html-react-parser';
import { FlowerBlipBlop } from '../components/BlipBlops.js';

const Softer = (props) => {

  const { rotation, windowDimensions, showSideMenu, showHeader } = props;
  const { windowHeight } = windowDimensions;

  const [data, setData] = useState({});
  const { text } = data;

  const fetchSingletonData = (singletonName) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/${singletonName}?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {
      setData(response);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    fetchSingletonData('privacy_policy');
    document.title = "Softer | Privacy Policy";
    showSideMenu();
    showHeader();
  }, [showSideMenu, showHeader]);

  return(
    <div className="view view--page--privacy-policy">
      <div
        className="view--page--privacy-policy__inner"
        style={{
          height: windowHeight + 'px'
        }}
      >
        <div className="view--page--privacy-policy__content">
          <div className="view--page--privacy-policy__text">{text ? parse(text) : ''}</div>
          <div
            className="privacy-policy__flowers__wrapper"
            style={{
              height: windowHeight + 'px'
            }}
          >
            <FlowerBlipBlop rotation={rotation} fill={`transparent`} stroke={`#F5EE63`} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Softer;