import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo.js';
import { BlipBlopsFormation } from './BlipBlops.js';
import Mailchimp from './MailchimpSignupForm.js';

const MailchimpSignupForm = (props) => {

  const { hideMailingListSignupForm } = props;

  const onSuccessCallback = () => {
    setTimeout(() => {
      hideMailingListSignupForm();
    }, 600);
  }

  const onErrorCallback = (e) => {
  }

  const onAlreadySignedUpCallback = () => {
  }

  return (
    <Mailchimp
      action='https://website.us7.list-manage.com/subscribe/post?u=0be85a5fe3fae12f6fe074d8c&amp;id=31617813cb'
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Email',
          type: 'email',
          required: true
        },
        {
          name: 'NAME',
          placeholder: 'Name',
          type: 'text',
          required: true
        }
      ]}
      messages = {
        {
          sending: "Sending...",
          success: "Thank you for subscribing!",
          error: "An unexpected internal error has occurred.",
          empty: "You must write an e-mail.",
          duplicate: "Too many subscribe attempts for this email address",
          button: "Subscribe!"
        }
      }
      onSuccessCallback={onSuccessCallback}
      onErrorCallback={onErrorCallback}
      onAlreadySignedUpCallback={onAlreadySignedUpCallback}
      resetOnSuccess={true}
      className="mailchimp-form"
      buttonClassName="mailchimp-form__button--submit"
    />
  )
}

const SocialMenu = (props) => {

  const { windowWidth, windowHeight, rotation, landingPageData } = props;
  const [unitWidth, setUnitWidth] = useState(0);
  const [unitsPerBlipBlopsFormation, setUnitsPerBlipBlopsFormation] = useState(0);
  const [mailingListSignupFormIsVisible, setMailingListSignupFormIsVisible] = useState(false);

  const showMailingListSignupForm = () => {
    setMailingListSignupFormIsVisible(true);
  }

  const hideMailingListSignupForm = () => {
    setMailingListSignupFormIsVisible(false);
  }

  const toggleMailingListSignupFormVisibility = () => {
    setMailingListSignupFormIsVisible(!mailingListSignupFormIsVisible);
  }

  useEffect(() => {

    const individualUnitWidth = (windowHeight - 80) / 44;
    //3 for the flower, 15 for instagram, 12 for mail, 15 for newsletter (3 for luck)
    const unitsUsed = 3 + 15 + 12 + 15 + 3;
    const widthUsed = unitsUsed * individualUnitWidth;
    // 60 for 20px margin on either side of screen AND one between flower and list
    const remainingWidth = windowWidth - widthUsed;
    const remainingUnits = remainingWidth / individualUnitWidth;

    setUnitsPerBlipBlopsFormation(
      remainingUnits / 3
    );

    setUnitWidth(
      individualUnitWidth
    );

  }, [windowWidth, windowHeight]);

  return (
    <div className="menu--social__wrapper">
      <header className="menu--social">
        <Logo fill="transparent" /> {/* 3 */}
        <ul
          className="menu--social__list"
          style={{
            width: (windowWidth - (3 * unitWidth) - 40) + 'px',
            fontSize: windowWidth >= windowHeight ? ((windowHeight - 80) / 16) + 'px' : ((windowWidth - 80) / 16) + 'px'
          }}
        >
          {
            windowWidth >= 768 ?
              <BlipBlopsFormation totalUnits={Math.ceil(unitsPerBlipBlopsFormation)} rotation={rotation} fill={'#F5EE63'} /> : ''
          }
          <li className="menu--social__list__item menu--social__list__item--instagram"> {/* 15 */}
            <a target="_blank" rel="noreferrer" href={landingPageData.instagram} className="menu--social__list__item__link link--ui">Instagram</a>
          </li>
          {
            windowWidth >= 768 ?
            <BlipBlopsFormation totalUnits={Math.floor(unitsPerBlipBlopsFormation)} rotation={rotation} fill={'#F5EE63'} /> : ''
          }
          <li className="menu--social__list__item menu--social__list__item--mail"> {/* 7 */}
            <a target="_blank" rel="noreferrer" href={`mailto:${landingPageData.email}`} className="menu--social__list__item__link link--ui">Mail</a>
          </li>
          {
            windowWidth >= 768 ?
            <BlipBlopsFormation totalUnits={Math.floor(unitsPerBlipBlopsFormation)} rotation={rotation} fill={'#F5EE63'} /> : ''
          }
          <li className="menu--social__list__item menu--social__list__item--newsletter"> {/* 16 */}
            <button onClick={ toggleMailingListSignupFormVisibility } aria-label="Show mailing list signup form" className="menu--social__list__item__link link--ui">Newsletter</button>
          </li>
        </ul>
      </header>
      <AnimatePresence exitBeforeEnter>
        {
          mailingListSignupFormIsVisible === true ?
            <motion.div
              className="mailchimp__wrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.4,
                delay: 0,
                ease: "easeInOut"
              }}
            >
              <MailchimpSignupForm showMailingListSignupForm={showMailingListSignupForm} hideMailingListSignupForm={hideMailingListSignupForm} />
            </motion.div>
          : ''
        }
      </AnimatePresence>
    </div>
  )
}

export default SocialMenu;