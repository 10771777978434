import Malarquee from 'react-malarquee';
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useSwipeable } from 'react-swipeable';
import { cockpitUrl, assetsPath } from '../cockpit.js';
import YoutubeVideo from '../components/YoutubeVideo.js';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '../components/Logo.js';
import Flower3D, { CopyFlower3D } from '../components/Flower3D.js';

let interactionTimeout;

const LivePlayer = (props) => {
  const { infoIsActive, chatIsActive, deactivateChat, deactivateInfo, data, windowDimensions } = props;
  const { youtube_url, image, off_air_text, video_file } = data;
  const { windowWidth, windowHeight } = windowDimensions;
  const [videoWidth, setVideoWidth] = useState(`${windowWidth}px`);
  const [videoHeight, setVideoHeight] = useState(`${windowHeight}px`);

  const resizeOffAirVideo = useCallback(() => {
    const video = document.querySelector('.live__player__off-air__video');
    if (video) {
      const videoNaturalWidth = video.videoWidth;
      const videoNaturalHeight = video.videoHeight;

      if (videoNaturalWidth / videoNaturalHeight > windowWidth / windowHeight) {
        setVideoWidth('auto');
        setVideoHeight(`${windowHeight}px`);
      } else {
        setVideoHeight('auto');
        setVideoWidth(`${windowWidth}px`);
      }
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (windowWidth && windowHeight) {
      resizeOffAirVideo();
    }
  }, [windowWidth, windowHeight, resizeOffAirVideo]);

  return (
    <div
      className={`live__player${infoIsActive === true || chatIsActive === true ? ' live__player--panel-is-active' : ''}`}
      onClick={() => {
        deactivateChat();
        deactivateInfo();
      }}
    >
      {
        youtube_url ?
          <YoutubeVideo url={youtube_url} />
          :
          video_file && video_file !== '' ?
            <div className="live__player__off-air__wrapper live__player__off-air__wrapper--video">
              <video
                className="live__player__off-air__video"
                src={assetsPath + video_file}
                autoPlay muted loop playsInline
                poster={image ? assetsPath + image.path : ''}
                onLoad={resizeOffAirVideo}
                style={{
                  width: videoWidth,
                  height: videoHeight
                }}
              />
            </div>
              :
            <div
              className="live__player__off-air__wrapper"
              style={{
                backgroundImage: image ? `url(${assetsPath + image.path})` : 'none'
              }}
            >
              <p className="live__player__off-air__text">{off_air_text}</p>
            </div>
      }
    </div>
  )
}

const LiveNav = (props) => {

  const { infoIsActive, activateInfo, deactivateInfo, discordIsActive, activateDiscord, deactivateDiscord, chatIsActive, activateChat, deactivateChat, rotation, flowerDataUrl } = props;

  const toggleDiscordIsActive = () => {
    if (infoIsActive === true) {
      deactivateDiscord();
    } else {
      activateDiscord();
    }
  }

  const toggleInfoIsActive = () => {
    if (infoIsActive === true) {
      deactivateInfo();
    } else {
      activateInfo();
    }
  }

  const toggleChatIsActive = () => {
    if (chatIsActive === true) {
      deactivateChat();
    } else {
      activateChat();
    }
  }

  return (
    <nav
      className={`nav--3d-flowers${infoIsActive === true || chatIsActive === true || discordIsActive === true ? ' hidden' : ''}`}
      aria-hidden={infoIsActive === true || chatIsActive === true ? true : null}
    >
      <Link
        to="/"
        className="nav--3d-flowers__button nav--3d-flowers__button--chat logo--spinning--nav"
      >
        <Flower3D rotation={rotation} flowerDataUrl={flowerDataUrl} canvasWrapperID={'flower3DCanvas'} />
        <span className="nav--3d-flowers__button__text">
          Home
        </span>
      </Link>
      <button
        className="nav--3d-flowers__button nav--3d-flowers__button--chat logo--spinning--nav"
        onClick={toggleChatIsActive}
      >
        <CopyFlower3D rotation={rotation} flowerDataUrl={flowerDataUrl} canvasWrapperID={'flower3DCanvas1'} />
        <span className="nav--3d-flowers__button__text">
          Chat
        </span>
      </button>
      <button
        className="nav--3d-flowers__button nav--3d-flowers__button--info logo--spinning--nav"
        onClick={toggleInfoIsActive}
      >
        <CopyFlower3D rotation={rotation} flowerDataUrl={flowerDataUrl} canvasWrapperID={'flower3DCanvas2'} />
        <span className="nav--3d-flowers__button__text">
          Info
        </span>
      </button>
      <button
        className="nav--3d-flowers__button nav--3d-flowers__button--info logo--spinning--nav"
        onClick={toggleDiscordIsActive}
      >
        <CopyFlower3D rotation={rotation} flowerDataUrl={flowerDataUrl} canvasWrapperID={'flower3DCanvasCopy3'} />
        <span className="nav--3d-flowers__button__text">
          Discord
        </span>
      </button>
    </nav>
  )
}

const LiveDiscordPanel = (props) => {

  const { discordIsActive, deactivateDiscord, data } = props;
  const { discord } = data;

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => deactivateDiscord()
  });

  return (
    <div
      className={`live__panel live__panel--discord${discordIsActive === true ? ' active' : ''}`}
      {...swipeHandlers}
    >
      <div className="live__panel--discord__inner">
        <div className="live__discord__wrapper">
          <iframe
            title="discord widget"
            src={discord}
            width="350" height="500"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts" />
        </div>
        <button
          className="live__panel__close live__discord__close"
          aria-label="Close Discord"
          onClick={deactivateDiscord}
        >close</button>
      </div>
    </div>
  )
}

const LiveChatPanel = (props) => {

  const { chatIsActive, deactivateChat } = props;

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => deactivateChat()
  });

  return (
    <div
      className={`live__panel live__panel--chat${chatIsActive === true ? ' active' : ''}`}
      {...swipeHandlers}
    >
      <div className="live__panel--chat__inner">
        <h2 className="live__panel__title live__chat__title">Chat</h2>
        <button
          className="live__panel__close live__chat__close"
          aria-label="Close chat"
          onClick={deactivateChat}
        >close</button>
        <div className="live__chatango__wrapper">
          <iframe title="Chatango" frameBorder="none" src={`https://softer-chatango-embed.netlify.app/`} />
        </div>
      </div>
    </div>
  )
}

const LiveInfoPanel = (props) => {

  const { infoIsActive, rotation, deactivateInfo, data } = props;
  const { info_text, info_title, date, time, timezone } = data;

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => deactivateInfo()
  });

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;

    if (minutes === '00') {
      strTime = hours + ' ' + ampm;
    }
    return strTime;
  }

  return (
    <div
      className={`live__panel live__panel--info${infoIsActive === true ? ' active' : ''}`}
      {...swipeHandlers}
    >
      {
        infoIsActive === true ?
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.4,
              delay: 0,
              ease: "easeInOut"
            }}
            className="live__info__wrapper"
          >
            <div className="live__panel--info__inner">
              {
                infoIsActive === true && info_title ?
                  <h3 className="live__info__title font--miu-miu-blur">
                    { info_title }
                  </h3> : ''
              }
              <div className="live__info__inner">
                <h3 className="live__info__subtitle">
                  <span className="live__info__subtitle__span live__info__subtitle__flower__wrapper">
                    <Logo fill="transparent" stroke="#000" rotation={rotation} />
                  </span>
                  <span className="live__info__subtitle__span">{
                    date ? new Date(date).getDate() + '/' + (parseInt(new Date(date).getMonth()) + 1) + ' ' + new Date(date).getFullYear()
                    : ''
                  }</span>
                  <span className="live__info__subtitle__span live__info__subtitle__flower__wrapper">
                    <Logo fill="transparent" stroke="#000" rotation={rotation} />
                  </span>
                  <span className="live__info__subtitle__span">{
                    time && date ?
                      formatAMPM(new Date(date + ' ' + time)) + ' ' + timezone
                      : ''
                  }</span>
                  <span className="live__info__subtitle__span live__info__subtitle__flower__wrapper">
                    <Logo fill="transparent" stroke="#000" rotation={rotation} />
                  </span>
                </h3>
                <div className="live__info__text__wrapper">
                  <div className="live__info__text">
                    {info_text ? parse(info_text) : ''}
                  </div>
                </div>
              </div>
            </div>
            <button
              className="live__panel__close live__info__close"
              aria-label="Close info"
              onClick={deactivateInfo}
            >close</button>
            {
              data.files ?
                <a
                  className="live__button--download button--download"
                  href={assetsPath + data.files}
                  download={true}
                >Download Files</a>
                :
                ''
            }
          </motion.div> : ''
      }
    </div>
  )
}

const Live = (props) => {

  const { windowDimensions, rotation, liveData, showSideMenu, hideSideMenu, sideMenuIsHidden, flowerDataUrl, showHeader, hideHeader } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [infoIsActive, setInfoIsActive] = useState(false);
  const [chatIsActive, setChatIsActive] = useState(false);
  const [discordIsActive, setDiscordIsActive] = useState(false);
  const [chatIsInitialised, setChatIsInitialised] = useState(0);

  const [data, setData] = useState(liveData);

  const deactivateDiscord = () => {
    setDiscordIsActive(false);
    showHeader();
  }

  const activateDiscord = () => {
    setDiscordIsActive(true);
    deactivateInfo();
    deactivateChat();
    hideHeader();
  }

  const deactivateInfo = () => {
    setInfoIsActive(false);
    showHeader();
  }

  const fetchLiveData = () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/classroom?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {
      setData(response);
    })
    .catch(error => {
      console.log(error);
    })
  }

  const hideUI = useCallback(() => {
    hideHeader();
    hideSideMenu();
  }, [hideHeader, hideSideMenu]);

  const deactivateChat = () => {
    setChatIsInitialised(chatIsInitialised + 1);
    setChatIsActive(false);
    showHeader();
  }

  const activateInfo = () => {
    setInfoIsActive(true);
    deactivateChat();
    deactivateDiscord();
    hideHeader();
  }

  const activateChat = () => {
    setChatIsInitialised(chatIsInitialised + 1);
    setChatIsActive(true);
    deactivateInfo();
    deactivateDiscord();
    hideHeader();
  }

  const handleInteractWithPage = () => {
    if (infoIsActive === false && chatIsActive === false && discordIsActive === false) {
      showSideMenu();
      showHeader();
      clearTimeout(interactionTimeout);
      interactionTimeout = setTimeout(hideUI, 5000);
    }
  }

  useEffect(() => {
    // let rotationAmount = 0;
    // let requestRotate;

    // const rotate = () => {
    //   const amount = -4;
    //   updateRotation(rotationAmount);
    //   rotationAmount = (rotationAmount + amount) % 3600;
    //   requestRotate = requestAnimationFrame(rotate);
    // }
    // cancelAnimationFrame(requestRotate)
    // requestRotate = requestAnimationFrame(rotate);

    fetchLiveData();
    document.title = "Softer | Live";

    // return () => {
    //   cancelAnimationFrame(requestRotate);
    // }

  }, []);

  return(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.9,
        delay: 0,
        ease: "easeInOut"
      }}
      onMouseMove={handleInteractWithPage}
      onTouchMove={handleInteractWithPage}
      className="view view--page--live"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <LivePlayer infoIsActive={infoIsActive} chatIsActive={chatIsActive} deactivateInfo={deactivateInfo} deactivateChat={deactivateChat} data={data} windowDimensions={windowDimensions} />
      <LiveInfoPanel infoIsActive={infoIsActive} windowWidth={windowWidth} windowHeight={windowHeight} rotation={rotation} deactivateInfo={deactivateInfo} data={data} />
      {
        data.chatango ?
          <LiveChatPanel chatIsActive={chatIsActive} chatIsInitialised={chatIsInitialised} deactivateChat={deactivateChat} data={data} /> : ''
      }
      <LiveDiscordPanel discordIsActive={discordIsActive} deactivateDiscord={deactivateDiscord} data={data} />
      <LiveNav
        discordIsActive={discordIsActive}
        activateDiscord={activateDiscord}
        deactivateDiscord={deactivateDiscord}
        infoIsActive={infoIsActive}
        activateInfo={activateInfo}
        deactivateInfo={deactivateInfo}
        chatIsActive={chatIsActive}
        activateChat={activateChat}
        deactivateChat={deactivateChat}
        sideMenuIsHidden={sideMenuIsHidden}
        rotation={rotation}
        flowerDataUrl={flowerDataUrl}
        key={`live_nav`}
      />
      <AnimatePresence>
        {
          sideMenuIsHidden === false ?
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.2,
                delay: 0,
                ease: "easeInOut"
              }}
              className="page--live__marquee__wrapper"
              key={`live_marquee_wrapper`}
            >
              <Malarquee rate={20}>
                <span className="text--ui yellow">{data.marquee_bottom ? data.marquee_bottom : 'A network for shaping softer digital futures'}</span>
              </Malarquee>
            </motion.div> : ''
        }
      </AnimatePresence>
    </motion.div>
  )
};

export default Live;