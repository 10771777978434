import * as THREE from 'three';
import { useState, useEffect, Suspense, useRef } from 'react';
import { cockpitUrl } from './cockpit.js';
import { BlipBlopsTile12 } from './components/BlipBlops.js';
import modelSrc from '../models/logo_high_res_smaller.glb';
import equirectangularImg from '../img/cubemap/Hdri_spherical_compressed_60.jpg';
import { Canvas, useFrame } from '@react-three/fiber';
import { useTexture, useGLTF, useProgress } from '@react-three/drei';

const ShinyReflectiveMaterial = (props) => {

  const { materialRef, hdri } = props;
  const envMap = useTexture(hdri);

    return (
      <meshPhysicalMaterial
        ref={materialRef}
        flatShading={false}
        roughness={0}
        metalness={0.99}
        transparent={true}
        opacity={0.75}
        reflectivity={0.99}
        needsUpdate={true}
        refractionRatio={0.6}
        side={THREE.DoubleSide}
      >
        {
          hdri &&
          <primitive
            attach="envMap"
            object={envMap}
          />
        }
      </meshPhysicalMaterial>
    )
}

const lerp = (start, end, amount) => {
  return (1 - amount) * start + amount * end
}

const SceneModel = (props) => {

  const { modelPath, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const mesh = useRef();
  const model = useGLTF(modelPath);
  const material = useRef();
  const [scale, setScale] = useState((windowWidth / windowHeight) * 20);
  const targetRotation = useRef({ x: 0, y: 0, z: 0 });

  useEffect(() => {
    setScale((windowWidth / windowHeight) * 20);
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (model.scene && material.current) {
      model.scene.traverse(
        (child) => {
          if (child.isMesh) {
            child.material = material.current;
          }
        }
      );
    }
  }, [model]);

  useFrame(({ mouse }) => {
    if (mesh.current) {
      if (!window.deviceMotionIsSupported) {
        targetRotation.current.x = mouse.y * Math.PI / -6;
        targetRotation.current.y = mouse.x * Math.PI / 8;
      } else {
        targetRotation.current.x = -(window.motion.x) / 360 * Math.PI;
        // targetRotation.current.y = -(window.motion.y) / 360 * Math.PI;
      }
      mesh.current.rotation.x = lerp(mesh.current.rotation.x, targetRotation.current.x, 0.1);
      mesh.current.rotation.y = lerp(mesh.current.rotation.y, targetRotation.current.y, 0.1);
    }
  });

  if (model.scene) {
    return (
      <group
        scale={[scale, scale, scale]}
        position={[0, 0, -60]}
      >
        <mesh ref={mesh}>
          <primitive object={model.scene} />
          <ShinyReflectiveMaterial {...props} materialRef={material} />
        </mesh>
      </group>
    )
  }
}

const SceneBackground = (props) => {
  const { hdri } = props;
  const equirectangularTexture = useTexture(hdri);
  const mesh = useRef();
  const targetRotation = useRef({ x: 0, y: 0, z: 0 });

  useFrame(({ mouse }) => {
    if (mesh.current) {
      if (!window.deviceMotionIsSupported) {
        targetRotation.current.x = mouse.y * Math.PI / -8;
        targetRotation.current.y = mouse.x * Math.PI / 10;
      } else {
        targetRotation.current.x = -((window.motion.x) / 180 * Math.PI) + Math.PI / 2;
        targetRotation.current.y = -((window.motion.y) / 180 * Math.PI);
      }
      mesh.current.rotation.x = lerp(mesh.current.rotation.x, targetRotation.current.x, 0.05);
      mesh.current.rotation.y = lerp(mesh.current.rotation.y, targetRotation.current.y, 0.05);
    }
  });

  return (
    <mesh ref={mesh}>
      <sphereBufferGeometry args={[-200, 64]} />
      <meshBasicMaterial>
        <primitive
          attach="map"
          object={equirectangularTexture}
          mapping={THREE.EquirectangularRefractionMapping}
          format={THREE.RGBFormat}
          encoding={THREE.sRGBEncoding}
          anisotropy={4}
        />
      </meshBasicMaterial>
    </mesh>
  )
}

const LandingPageScene = (props) => {
  const { hdri, modelPath, landingPageData, landingPageDataIsSet } = props;

  const [modelIsFetchable, setModelIsFetchable] = useState(false);
  const group = useRef();

  useEffect(() => {
    if (landingPageData.object && landingPageData.object !== '' && landingPageDataIsSet === true) {
      fetch(modelPath)
        .then((response) => {
          setModelIsFetchable(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [landingPageData.object, landingPageDataIsSet, modelPath]);

  return (
    <group ref={group}>
      <pointLight position={[2, 3, 10]} />
      {
        hdri !== '' &&
        <SceneBackground {...props} hdri={hdri} />
      }
      {
        hdri !== '' &&
        modelPath && modelPath !== '' && modelIsFetchable === true &&
        <SceneModel {...props} modelPath={modelPath} />
      }
    </group>
  )
}

const Loader = (props) => {

  const { setSceneIsLoaded } = props;
  const { progress } = useProgress();

  useFrame(() => {
    if (progress === 100) {
      setSceneIsLoaded(true);
    }
  });

  return null
}

const Background = (props) => {

  const { landingPageData, landingPageDataIsSet, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [isLoaded, setIsLoaded] = useState(false);
  const [loaderRotation, setLoaderRotation] = useState(0);
  const [hdri, setHdri] = useState('');
  const [modelPath, setModelPath] = useState('');

  useEffect(() => {
    let hdriString = equirectangularImg;
    if (Object.prototype.toString.call(landingPageData.hdri) !== '[object Array]') {
      if (landingPageData.hdri?.path) {
        hdriString = cockpitUrl + '/' + landingPageData.hdri.path;
      }
    }
    setHdri(hdriString);

    let modelString = modelSrc;
    if (landingPageData.object && landingPageData.object !== '' && landingPageDataIsSet === true) {
      modelString = cockpitUrl + '/' + landingPageData.object;
    }
    setModelPath(modelString);

  }, [landingPageData.hdri, landingPageData.object, landingPageDataIsSet]);

  useEffect(() => {
    let loaderRotationNumber = 0;
    let raf;

    const rotateLoader = () => {
      if (isLoaded === false) {
        loaderRotationNumber+= 10;
        setLoaderRotation(loaderRotationNumber);
        raf = requestAnimationFrame(rotateLoader);
      }
    }


    raf = requestAnimationFrame(rotateLoader);

    return () => {
      cancelAnimationFrame(raf);
    }
  }, [isLoaded]);

  return (
    <div className="background__wrapper full-height">
      <div className="background__blipBlops__wrapper">
        <BlipBlopsTile12 rotation={loaderRotation} fill={`#F5EE63`} />
      </div>
      <div
        id="background"
        className={`background${isLoaded === true ? ' loaded' : ''}`}
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px'
        }}
      >
        <Canvas>
          <Loader setSceneIsLoaded={setIsLoaded} />
          <Suspense fallback={null}>
            <LandingPageScene {...props} hdri={hdri} modelPath={modelPath} isLoaded={isLoaded} setLoaderRotation={setLoaderRotation} />
          </Suspense>
        </Canvas>
      </div>
    </div>
  )
}

export default Background;
export { ShinyReflectiveMaterial };