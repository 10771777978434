import { useRef, useState, useEffect } from 'react';
import flower from '../../models/flower_smaller.glb';
import * as THREE from 'three';
import equirectangularImg from '../../img/cubemap/Hdri_spherical_compressed_60.jpg';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import FPSLimiter from './FPSLimiter';

const Flower3DScene = (props) => {
  const { flowerCanvas } = props;
  const model = useGLTF(flower);
  const mesh = useRef();
  const [modelClone, setModelClone] = useState({ undefined: true });
  const materialRef = useRef({ undefined: true });
  const [materialNeedsSetting, setMaterialNeedsSetting] = useState(0);
  const envMap = useTexture(equirectangularImg);

  useEffect(() => {
    if (model.scene && materialRef.current && materialNeedsSetting !== -1) {
      setMaterialNeedsSetting(-1);
      model.scene.traverse(
        (child) => {
          if (child.isMesh) {
            if (materialRef.current) {
              child.material = materialRef.current;
            }
          }
        }
      );
      setModelClone(model.scene.clone());
    } else {
      if (materialNeedsSetting !== -1) {
        setMaterialNeedsSetting(materialNeedsSetting + 1);
      }
    }
  }, [model.scene, materialRef.current.undefined, materialNeedsSetting]);


  useFrame(({ gl }) => {
    if (mesh.current) {
      if (window.rotation) {
        mesh.current.rotation.y = window.rotation / 360 * (Math.PI * 2);
      }
    }
    const images = document.querySelectorAll('.spinning-flower__img');
    if (flowerCanvas.current && images[0]) {
      const dataUrl = flowerCanvas.current.toDataURL();
      for (let img of images) {
        img.setAttribute('src', dataUrl);
      }
      gl.clear();
    }
  })

  return (
    <group>
      <pointLight
        color={0xffffff}
        intensity={0.3}
        position={[30, 200, -100]}
      />
        <mesh
          ref={mesh}
          scale={[8, 8, 8]}
          position={[0, 0, 0]}
      >
        <meshPhysicalMaterial
          ref={materialRef}
          flatShading={false}
          roughness={0}
          metalness={0.99}
          transparent={true}
          opacity={0.75}
          reflectivity={0.99}
          needsUpdate={true}
          refractionRatio={0.6}
          side={THREE.DoubleSide}
        >
          <primitive
            attach="envMap"
            object={envMap}
            encoding={THREE.sRGBEncoding}
            format={THREE.RGBAFormat}
            mapping={THREE.EquirectangularReflectionMapping}
          />
        </meshPhysicalMaterial>
        <primitive object={modelClone} />
      </mesh>
    </group>
  )
}

const Flower3D = (props) => {


  const flowerCanvas = useRef();

  return (
    <div className="spinning-flower__wrapper">
      <Canvas gl={{ preserveDrawingBuffer: true }} ref={flowerCanvas}>
        <FPSLimiter fps={12} />
        {/* <Suspense fallback={null} > */}
          <Flower3DScene {...props} flowerCanvas={flowerCanvas} />
        {/* </Suspense> */}
      </Canvas>
    </div>
  )
}

const CopyFlower3D = (props) => {

  // const { flowerDataUrl } = props;

  return (
    <div className="spinning-flower__wrapper copy-3d-flower__wrapper">
      <img src={''} className="spinning-flower__img logo--spinning--nav__img" alt="Spinning 3D flower logo" />
    </div>
  )
}

export default Flower3D;
export { CopyFlower3D };