import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FlowerBlipBlop } from '../components/BlipBlops.js';
import Logo from '../components/Logo.js';
import { motion, AnimatePresence } from 'framer-motion';
import { assetsPath } from '../cockpit.js';
import * as _ from 'underscore';

const Filter = (props) => {

  const { name, changeActiveFilters, activeFilters } = props;
  const [isActive, setIsActive] = useState(false);

  const activateFilter = () => {
    const newActiveFilters = [];
    for (let i = 0; i < activeFilters.length; i++) {
      if (activeFilters[i] !== name) {
        newActiveFilters.push(activeFilters[i]);
      }
    }
    setIsActive(true);
    newActiveFilters.push(name);
    changeActiveFilters(newActiveFilters);
  }

  const deactivateFilter = () => {
    const newActiveFilters = [];
    for (let i = 0; i < activeFilters.length; i++) {
      if (activeFilters[i] !== name) {
        newActiveFilters.push(activeFilters[i]);
      }
    }
    setIsActive(false);
    changeActiveFilters(newActiveFilters);
  }

  const handleClickFilter = (e) => {
    const filterName = e.target.name;
    if (isActive === true) {
      deactivateFilter(filterName);
    } else {
      activateFilter(filterName);
    }
  }

  return (
    <label
      className={`resources__button resources__filter__label${ isActive === true ? ' active' : '' }`}
      data-name={name}
      htmlFor={name}
    >
      {name}
      <input
        type="checkbox"
        className="resources__filter"
        name={name}
        onClick={handleClickFilter}
      />
    </label>
  )
}

const Filters = (props) => {

  const [resourcesFiltersAreActive, setResourcesFiltersAreActive] = useState(false);
  const { changeActiveFilters, activeFilters, filters } = props;

  const toggleResourcesFiltersAreActive = () => {
    setResourcesFiltersAreActive(!resourcesFiltersAreActive);
  }

  return (
    <nav className={`resources__filters${resourcesFiltersAreActive === true ? ' active' : ''}`}>
      <div className={`resources__filters__outer${resourcesFiltersAreActive === true ? ' active' : ''}`}>
        <AnimatePresence>
          {
            resourcesFiltersAreActive === true &&
            <motion.div
              className="filters__inner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.2,
                delay: 0.2,
                ease: "easeInOut"
              }}
            >
            <AnimatePresence>
                {
                  filters.map(
                      (filter, i) => (
                        <Filter
                          className="resources__filter"
                          key={i}
                          name={filter}
                          changeActiveFilters={changeActiveFilters}
                          activeFilters={activeFilters}
                        />
                      )
                  )
                }
              </AnimatePresence>
            </motion.div>
          }
        </AnimatePresence>
      </div>
      <button
        className={`resources__filters__button--toggle${resourcesFiltersAreActive === true ? ' active' : ''}`}
        onClick={toggleResourcesFiltersAreActive}
        aria-label={`${resourcesFiltersAreActive === true ? 'Hide ' : 'Show '}filters`}
      >
        <AnimatePresence>
          {
            resourcesFiltersAreActive === false &&
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut"
                }}
                className="resources__filters__button--toggle__text"
              >
                Filters
              </motion.span>
          }
        </AnimatePresence>
        <div className={`resources__filters__button--toggle__flower__wrapper${resourcesFiltersAreActive === true ? ' active' : ''}`}>
          <FlowerBlipBlop fill="#000" stroke="transparent" />
        </div>
      </button>
    </nav>
  )
}

const ResourcesGridThumbnail = (props) => {

  const { item, index, columnIndex, showSideMenu, hideSideMenu, windowDimensions, columnCount } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [copyIsVisible, setCopyIsVisible] = useState(false);
  const randomIdentifier = Math.floor(Math.random() * 4200);

  useEffect(() => {
    const type = document.querySelector(`#resourcesThumbnailCopyType${index}_${columnIndex}_${randomIdentifier}`);
    if (type && copyIsVisible) {
      type.removeAttribute('style');
      type.style.fontSize = '20px';
      const fontSize = 20;
      const typeWidth = type.offsetWidth;
      const thumbnailWidth = (windowWidth / columnCount) - 24;
      const newFontSize = (fontSize / typeWidth) * thumbnailWidth;
      type.style.fontSize = Math.floor(newFontSize) + 'px';
    }
  }, [windowWidth, windowHeight, copyIsVisible, columnCount, columnIndex, index, randomIdentifier]);

  const handleMouseEnter = (e) => {
    setCopyIsVisible(true);
    if (columnIndex === 0) {
      const x = e.clientX ? e.clientX : e.touches ? e.touches[0] ? e.touches[0].clientX : -1 : -1;
      if (x >= (((windowHeight - 80) / 44) * 2) + 6) {
        hideSideMenu();
      } else {
        showSideMenu();
      }
    }
  }

  const handleMouseMove = (e) => {
    setCopyIsVisible(true);
    if (columnIndex === 0) {
      const x = e.clientX ? e.clientX : e.touches ? e.touches[0] ? e.touches[0].clientX : -1 : -1;
      if (x >= (((windowHeight - 80) / 44) * 2) + 6) {
        hideSideMenu();
      } else {
        showSideMenu();
      }
    }
  }

  const handleMouseLeave = () => {
    setCopyIsVisible(false);
    showSideMenu();
  }

  return (
    <div
      className="resources__thumbnail"
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Link className="resources__thumbnail__link" to={`/resources/${item._id}`}>
        <img
          src={item.thumbnail_image ? assetsPath + item.thumbnail_image.path : ''}
          alt={item.thumbnail_image ? item.thumbnail_image.title : ''}
          className="resources__thumbnail__img"
        />
        <AnimatePresence>
          {
            copyIsVisible === true &&
              <motion.div
                className="resources__thumbnail__copy"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                  delay: 0,
                  ease: "easeInOut"
                }}
              >
                <div className="resources__thumbnail__copy__inner">
                  <h2 className="resources__thumbnail__copy__title">{item.title}</h2>
                  <div className="resources__thumbnail__copy__flower__wrapper">
                    <Logo fill="#F5EE63" />
                  </div>
                  <p
                    className="resources__thumbnail__copy__type"
                    id={`resourcesThumbnailCopyType${index}_${columnIndex}_${randomIdentifier}`}
                  >{item.type}</p>
                  <p className="resources__thumbnail__copy__info">{item.info}</p>
                </div>
              </motion.div>
          }
        </AnimatePresence>
      </Link>
    </div>
  )
}

const ResourcesGridColumn = ({ column, windowDimensions, index, columnCount, showSideMenu, hideSideMenu }) => {

  const { windowWidth, windowHeight } = windowDimensions;
  const [originalContentHeight, setOriginalContentHeight] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [columnContent, setColumnContent] = useState(column);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const calculateColumns = useCallback((cumulativeHeight) => {
    const doubleWindowHeight = windowHeight * 2;
    const numberOfContentRepetitions = Math.ceil(doubleWindowHeight / cumulativeHeight) + 2;

    const columnContentArray = [];

    for (let i = 0; i < numberOfContentRepetitions; i++) {
      for (let j = 0; j < column.length; j++) {
        columnContentArray.push(column[j]);
      }
    }

    setTotalHeight(cumulativeHeight * numberOfContentRepetitions);
    setColumnContent(columnContentArray);

    const element = document.querySelector(`#resourcesColumn${index}`);
    if (element && hasUserScrolled === false) {
      element.scrollTo(0, cumulativeHeight + 1);
      setScrollY(cumulativeHeight + 1);
    }
  }, [windowHeight, column, index, hasUserScrolled]);

  const calculateCumulativeHeight = useCallback(() => {

    let cumulativeHeight = 1;
    let loadedImagesCount = 0;

    const onImageLoad = (e) => {
      const img = e.target;

      loadedImagesCount = loadedImagesCount + 1;
      const imgHeightOnScreen = Math.floor((img.naturalHeight / img.naturalWidth) * (windowWidth / columnCount));
      if (img.naturalWidth) {
        cumulativeHeight = cumulativeHeight + imgHeightOnScreen;
      }
      if (loadedImagesCount === column.length) {
        //what to do after this
        setOriginalContentHeight(cumulativeHeight);
        calculateColumns(cumulativeHeight);
      }
    }

    for (let i = 0; i < column.length; i++) {
      if (column[i].thumbnail_image) {
        const src = assetsPath + column[i].thumbnail_image.path;
        if (src) {
          const img = document.createElement('img');
          img.onload = onImageLoad;
          img.src = src;
        }
      }
    }
  }, [column, columnCount, windowWidth, calculateColumns, setOriginalContentHeight]);

  useEffect(() => {
    calculateCumulativeHeight();

  }, [calculateCumulativeHeight]);

  const handleScroll = (e) => {
    if (totalHeight > 0) {
      setHasUserScrolled(true);
      const element = e.target;
      const scrollTop = element.scrollTop;
      if (scrollTop === 0 && scrollTop < scrollY) {
        element.scrollTop = originalContentHeight - 1;
      } else
      if (Math.ceil(scrollTop) >= originalContentHeight && scrollTop > scrollY) {
        element.scrollTop = 0;
      }
      setScrollY(element.scrollTop);
    }
  }

  const handleScrollThrottled = _.throttle(handleScroll, 30);

  return (
    <div
      className="resources__grid__column"
      id={`resourcesColumn${index}`}
      style={{
        height: windowHeight + 'px',
        width: windowWidth / columnCount + 'px'
      }}
      onScroll={handleScrollThrottled}
    >
    {
      columnContent.map(
        (item, i) => (
          <ResourcesGridThumbnail
            item={item}
            windowDimensions={windowDimensions}
            index={i}
            columnIndex={index}
            key={i}
            showSideMenu={showSideMenu}
            hideSideMenu={hideSideMenu}
            columnCount={columnCount}
          />
        )
      )
    }
    </div>
  )

}

const Resources = (props) => {

  const [filteredData, setFilteredData] = useState([]);
  const [isInitialised, setIsInitialised] = useState(false);

  const convertWindowWidthToColumnCount = (windowWidth) => {
    if (windowWidth < 500) {
      return 1;
    } else if (windowWidth < 1024) {
      return 2
    } else if (windowWidth < 1920) {
      return 3
    } else {
      return 4
    }
  }

  const { windowDimensions, showSideMenu, hideSideMenu, resourcesData, fetchResourcesData } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [columnCount, setColumnCount] = useState(convertWindowWidthToColumnCount(windowWidth));
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const resetFilters = () => {
    if (resourcesData[0]) {
      setFilteredData(resourcesData);
    }
  }

  const changeActiveFilters = (updatedFiltersList) => {
    if (!updatedFiltersList[0]) {
      resetFilters();
    } else {
      const allEntries = resourcesData;
      const filteredEntries = [];
      for (let entry of allEntries) {
        let isEntryIncluded = false;
        for (let tag of entry.tags) {
          for (let filter of updatedFiltersList) {
            if (filter === tag) {
              isEntryIncluded = true;
            }
          }
        }
        if (isEntryIncluded === true) {
          filteredEntries.push(entry);
        }
      }
      setFilteredData(filteredEntries);
    }
    setActiveFilters(updatedFiltersList);
  }

  const getAllTags = useCallback((entries) => {
    const tagsArray = [];
    for (let entry of entries) {
      for (let tag of entry.tags) {
        let tagAlreadyExists = false;
        for (let existingTag of tagsArray) {
          if (existingTag === tag) {
            tagAlreadyExists = true;
          }
        }
        if (tagAlreadyExists === false) {
          tagsArray.push(tag);
        }
      }
    }
    setFilters(tagsArray);
  }, []);

  const arrangeThumbnails = useCallback((entries) => {
    setColumnCount(convertWindowWidthToColumnCount(windowWidth));
    const columnsArray = [];
    for (let i = 0; i < columnCount; i++) {
      columnsArray.push([]);
    }
    if (entries.length >= columnsArray.length) {
      for (let i = 0; i < entries.length; i++) {
        columnsArray[i % columnCount].push(entries[i]);
      }
    } else {
      for (let i = 0; i < columnsArray.length; i++) {
        columnsArray[i].push(entries[i % entries.length]);
      }
    }
    setColumns(columnsArray);
  }, [columnCount, windowWidth]);

  useEffect(() => {
    document.title = "Softer | Resources";
    if (isInitialised === false) {
      if (!resourcesData[0]) {
        setIsInitialised(true);
        fetchResourcesData();
      }
    }
    if (resourcesData[0] && filteredData.length === 0) {
      setFilteredData(resourcesData);
      arrangeThumbnails(resourcesData);
      getAllTags(resourcesData);
    }
    if (filteredData[0]) {
      arrangeThumbnails(filteredData);
    }
  }, [windowWidth, windowHeight, fetchResourcesData, resourcesData, isInitialised, columnCount, setColumnCount, setColumns, filteredData, arrangeThumbnails, getAllTags]);

  return(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.9,
        delay: 0,
        ease: "easeInOut"
      }}
      className="view view--page--resources full-height"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div
        className="resources__grid"
        style={{
          minHeight: windowHeight + 'px'
        }}
      >
        {
          columns[0] ?
            columns.map(
              (column, i) => (
                <ResourcesGridColumn
                  key={i}
                  column={column}
                  index={i}
                  columnCount={columnCount}
                  windowDimensions={windowDimensions}
                  showSideMenu={showSideMenu}
                  hideSideMenu={hideSideMenu}
                />
              )
            )
          : ''
        }
      </div>
      <Routes>
        <Route
          exact
          path="/resources"
          element={
            <Filters activeFilters={activeFilters} changeActiveFilters={changeActiveFilters} filters={filters} />
          }
        />
      </Routes>
    </motion.div>
  )
};

export default Resources;