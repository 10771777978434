
import { useEffect, useState } from 'react';
import Malarquee from 'react-malarquee';
import { cockpitUrl } from '../cockpit.js';
import parse from 'html-react-parser';
import { FlowerBlipBlopRotating } from '../components/BlipBlops.js';

const SofterFlowerBlipBlop = (props) => {

  const { rotation } = props;

  const { top } = props;
  const { left } = props;
  const { scale } = props;
  const [rotationAddition, setRotationAddition] = useState(0);

  useEffect(() => {
    setRotationAddition(Math.floor(Math.random() * 350));
  }, []);

  return (
    <div
      className="softer__flower__wrapper"
      style={{
        top: top + '%',
        left: left + '%',
        transform: `rotate(${rotationAddition}deg) scale(${ rotation < 1800 ? (rotation * 0.0016666 * scale) : (3600 - rotation) * 0.0016666 * scale})`
      }}
    >
      <FlowerBlipBlopRotating rotation={rotation} fill={`transparent`} stroke={`#F5EE63`} />
    </div>
  )
}

const SofterFlowerBlipBlops = ({ windowDimensions, rotation }) => {

  const { windowHeight } = windowDimensions;
  const [blipBlopsLengthArray, setBlipBlopsLengthArray] = useState([]);

  useEffect(() => {
    const blipBlopsArray = [
      {
        left: 0,
        top: 69,
        scale: 1.2
      },
      {
        left: 18,
        top: 12.5,
        scale: 0.6
      },
      {
        left: 60,
        top: 60,
        scale: 0.6
      },
      {
        left: 20,
        top: 40,
        scale: 0.9
      },
      {
        left: 96,
        top: 72,
        scale: 1.4
      },
      {
        left: 30,
        top: 20,
        scale: 0.2
      },
      {
        left: 50,
        top: 80,
        scale: 1.2
      },
      {
        left: 64,
        top: 20,
        scale: 1.1
      },
      {
        left: 100,
        top: 30,
        scale: 0.6
      },
      {
        left: 24,
        top: 90,
        scale: 0.4
      },
      {
        left: 100,
        top: 100,
        scale: 0.9
      },
      {
        left: 40,
        top: 50,
        scale: 0.6
      }
    ];
    setBlipBlopsLengthArray(blipBlopsArray);
  }, []);

  return (
    <div className="softer__flowers__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      {
        blipBlopsLengthArray.map(
          (item, i) => (
            <SofterFlowerBlipBlop
              rotation={rotation}
              fill={`transparent`}
              stroke={`#F5EE63`}
              key={i}
              {...item}
            />
          )
        )
      }
    </div>
  )
}

const Softer = (props) => {

  const { rotation, windowDimensions, showSideMenu, showHeader } = props;
  const { windowHeight } = windowDimensions;

  const [data, setData] = useState({});
  const { text, marquee_bottom, imprint } = data;

  const fetchSingletonData = (singletonName) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/${singletonName}?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {
      setData(response);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    fetchSingletonData('softer');
    document.title = "Softer | Info";
    showSideMenu();
    showHeader();
  }, [showSideMenu, showHeader]);

  return(
    <div className="view view--page--softer">
      <div
        className="view--page--softer__inner"
        style={{
          height: windowHeight + 'px'
        }}
      >
        <div className="view--page--softer__content">
          <div className="view--page--softer__text">{text ? parse(text) : ''}</div>
          <SofterFlowerBlipBlops windowDimensions={windowDimensions} rotation={rotation} />
          <div className="view--page--softer__text">
            <h2 className="font--miu-miu">Imprint</h2>
            {imprint ? parse(imprint) : ''}
          </div>
        </div>
      </div>
      <div className="page--softer__marquee__wrapper">
        {
          marquee_bottom ?
            <Malarquee rate={20}>
              <span className="text--ui yellow">{marquee_bottom}</span>
            </Malarquee> : ''
        }
      </div>
    </div>
  )
};

export default Softer;