import CookieConsent from "react-cookie-consent";

const CookiesNoticeContent = (props) => {
  return (
    <div className="cookies-notice__content">
      <p className="cookies-notice__text">
        We use cookies and things and we have to have this annoying bar. By pressing accept and by continuing to use this site, you agree to our <a
          className="cookies-notice__link--learn-more"
          href="/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >Privacy Policy</a>.
      </p>
    </div>
  )
};

const CookiesBanner = (props) => {

  const { handleAcceptCookies } = props;

  return (
      <CookieConsent
        debug
        containerClasses="cookies-notice"
        location="none"
        buttonText="Accept"
        disableButtonStyles
        disableStyles
        buttonClasses="cookies-notice__option cookies-notice__button cookies-notice__button--accept"
        buttonWrapperClasses="cookies-notice__options"
        children={
          (
            <CookiesNoticeContent />
          )
        }
        onAccept={
          () => {
            handleAcceptCookies();
          }
        }
      />
  )
}

export default CookiesBanner;