import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { assetsPath } from '../cockpit.js';
import YoutubeVideo from '../components/YoutubeVideo.js';

const Resource = (props) => {

  const { resourcesData, blurContent, unblurContent } = props;
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [filePath, setFilePath] = useState('');

  useEffect(() => {
    if (resourcesData[0]) {
      blurContent();
      for (let resource of resourcesData) {
        const id = resource._id;
        if (window.location.href.indexOf(id) > -1) {
          if (resource.youtube_url) {
            setYoutubeUrl(resource.youtube_url);
            if (resource.files && resource.files !== '') {
              setFilePath(resource.files);
            }
            document.title = "Softer | " + resource.title;
          }
        }
      }
    }
  }, [resourcesData, blurContent]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.9,
        delay: 0,
        ease: "easeInOut"
      }}
      className="resource__outer"
      style={{
        height: window.innerHeight + 'px'
      }}>
      <Link
        className="resource__overlay"
        to="/resources"
        style={{
          height: window.innerHeight + 'px'
        }}
        onClick={unblurContent}
      >
      </Link>
      <div className="resource__overlay__links">
        {
          filePath !== '' ?
            <a
              className="button--download resource__button--download"
              href={assetsPath + filePath}
              download={true}
            >Download Files</a>
            :
            ''
        }
        <Link
          className="resource__overlay__close"
          to="/resources"
          onClick={unblurContent}
        >Close
        </Link>
      </div>
      <div className="resource__wrapper">
        <YoutubeVideo url={youtubeUrl} />
      </div>
    </motion.div>
  )
};

export default Resource;