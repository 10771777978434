import { useState, useEffect } from 'react';

const CircleBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.45 21.45" width="21.45" height="21.45" className="blipBlop--h-1 blipBlop--w-1">
    <circle fill={rotation < 3400 && rotation > 1000 ? fill : 'transparent'} stroke={rotation < 3400 && rotation > 1000 ? stroke : 'transparent'} cx="10.73" cy="10.73" r="10.73"/>
  </svg>
);

const StackedCirclesBlipBlop = ({ fill, stroke, rotation }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.45 42.9" width="21.45" height="42.9" className="blipBlop--w-1 blipBlop--h-2">
      <circle fill={rotation < 2600 && rotation > 400 ? fill : 'transparent'} stroke={rotation < 2600 && rotation > 400 ? stroke : 'transparent'} cx="10.73" cy="32.18" r="10.73"/>
      <circle fill={rotation < 3000 && rotation > 1000 ? fill : 'transparent'} stroke={rotation < 3000 && rotation > 1000 ? stroke : 'transparent'} cx="10.73" cy="10.73" r="10.73"/>
    </svg>
  )
}

const SquareBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.45 21.45" width="21.45" height="21.45" className="blipBlop--h-1 blipBlop--w-1">
    <rect fill={rotation > 600 && rotation < 2900 ? fill : 'transparent'} stroke={rotation > 600 && rotation < 2900 ? stroke : 'transparent'} width="21.45" height="21.45" rx="8.1"/>
  </svg>
);

const DiagonalBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.9 42.9" width="42.9" height="42.9" className="blipBlop--h-2 blipBlop--w-2">
    <path fill={rotation < 2400 && rotation > 600 ? fill : 'transparent'} stroke={rotation < 2400 && rotation > 600 ? stroke : 'transparent'} d="M0,27.84c.13-.53.21-1.07.39-1.59A6.71,6.71,0,0,1,2.57,23,7.39,7.39,0,0,1,6,21.48a8.83,8.83,0,0,1,1.62-.18c2.39,0,4.77,0,7.16,0a6.57,6.57,0,0,0,4-1.49,6.74,6.74,0,0,0,2.45-4.25,5.61,5.61,0,0,0,.08-1.08c0-2.33,0-4.66,0-7a7.75,7.75,0,0,1,1.4-4.55A6.54,6.54,0,0,1,26.07.52,10.14,10.14,0,0,1,29,.07C30,0,31,0,32,0s2.3,0,3.45.08A8.43,8.43,0,0,1,39,.86a6.72,6.72,0,0,1,3.6,4.6,9.77,9.77,0,0,1,.23,2c0,2.23,0,4.47,0,6.7a8.24,8.24,0,0,1-.49,2.91,7.29,7.29,0,0,1-1.25,2.07,6.64,6.64,0,0,1-3.73,2.22,6.78,6.78,0,0,1-1.54.2c-2.12,0-4.25,0-6.37,0a10.14,10.14,0,0,0-3.15.36,7.06,7.06,0,0,0-2.23,1.19A6.56,6.56,0,0,0,21.74,27a8.85,8.85,0,0,0-.19,1.7c0,2.32,0,4.64,0,7a7.25,7.25,0,0,1-.82,3.48A6.93,6.93,0,0,1,16,42.72a8.41,8.41,0,0,1-1.81.16c-2.34,0-4.69,0-7,0A6.94,6.94,0,0,1,4,42.16,6.86,6.86,0,0,1,.24,37.4,5.62,5.62,0,0,1,.08,36c0-1.73,0-3.46,0-5.19A1.69,1.69,0,0,0,0,30.47Z"/>
  </svg>
);

const FlowerBlipBlop = (props) => {
  const { fill, stroke, rotation } = props;
  return (
    <div className="blipBlop--h-2 blipBlop--w-2 blipBlop--flower__wrapper">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.35 64.35" width="64.35" height="64.35" className="blipBlop--h-2 blipBlop--w-2">
        <path
          fill={rotation > 400 && rotation < 3400 ? fill ? fill : '' : 'transparent'}
          stroke={rotation > 400 && rotation < 3400 ? stroke ? stroke : '' : 'transparent'}
          d="M64.35,31.71a11.74,11.74,0,0,1-1.3,5.69,10,10,0,0,1-7.15,5.31,28.74,28.74,0,0,1-3.2.36,10,10,0,0,0-5.82,2.31,10.33,10.33,0,0,0-2.74,3.32,10.64,10.64,0,0,0-1,4.06,13.73,13.73,0,0,1-1.72,6.55,11.21,11.21,0,0,1-3.71,3.54,9.83,9.83,0,0,1-6.34,1.44,11.1,11.1,0,0,1-7.51-3.76,10.45,10.45,0,0,1-2.29-5.26c-.24-1.59-.36-3.19-.7-4.77a9.09,9.09,0,0,0-1.82-3.68,8.92,8.92,0,0,0-5.45-3.45,14.15,14.15,0,0,0-2.25-.3,12.34,12.34,0,0,1-7-2.18,10.48,10.48,0,0,1-4-6.15,10.52,10.52,0,0,1,2.12-9.42,9.93,9.93,0,0,1,6.27-3.73c1.32-.21,2.66-.26,4-.48a10.36,10.36,0,0,0,4.7-2,9.29,9.29,0,0,0,3.4-4.89,17.71,17.71,0,0,0,.54-3.55,11.12,11.12,0,0,1,3.18-7.59,11.05,11.05,0,0,1,16.1.74A9.51,9.51,0,0,1,42.8,8.62c.33,1.74.48,3.52.82,5.26a9.26,9.26,0,0,0,2,3.86,8.84,8.84,0,0,0,5.23,3.2,14.59,14.59,0,0,0,2.19.29,12,12,0,0,1,7.38,2.44,10.63,10.63,0,0,1,3.77,6.26A13.1,13.1,0,0,1,64.35,31.71Zm-21.75.46c.4-6-5.07-10.29-9.77-10.47a10.48,10.48,0,1,0-1.18,20.93C37.56,43,43.07,37.78,42.6,32.17Z" />
      </svg>
    </div>
  );
};

const FlowerBlipBlopRotating = ({ fill, stroke, rotation }) => (
  <div className="blipBlop--h-2 blipBlop--w-2 blipBlop--flower__wrapper"
  style={{
    transform: `rotate(${rotation * 0.25}deg)`
  }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.35 64.35" width="64.35" height="64.35" className="blipBlop--h-2 blipBlop--w-2">
      <path fill={rotation > 400 && rotation < 3400 ? fill : 'transparent'} stroke={rotation > 400 && rotation < 3400 ? stroke : 'transparent'} d="M64.35,31.71a11.74,11.74,0,0,1-1.3,5.69,10,10,0,0,1-7.15,5.31,28.74,28.74,0,0,1-3.2.36,10,10,0,0,0-5.82,2.31,10.33,10.33,0,0,0-2.74,3.32,10.64,10.64,0,0,0-1,4.06,13.73,13.73,0,0,1-1.72,6.55,11.21,11.21,0,0,1-3.71,3.54,9.83,9.83,0,0,1-6.34,1.44,11.1,11.1,0,0,1-7.51-3.76,10.45,10.45,0,0,1-2.29-5.26c-.24-1.59-.36-3.19-.7-4.77a9.09,9.09,0,0,0-1.82-3.68,8.92,8.92,0,0,0-5.45-3.45,14.15,14.15,0,0,0-2.25-.3,12.34,12.34,0,0,1-7-2.18,10.48,10.48,0,0,1-4-6.15,10.52,10.52,0,0,1,2.12-9.42,9.93,9.93,0,0,1,6.27-3.73c1.32-.21,2.66-.26,4-.48a10.36,10.36,0,0,0,4.7-2,9.29,9.29,0,0,0,3.4-4.89,17.71,17.71,0,0,0,.54-3.55,11.12,11.12,0,0,1,3.18-7.59,11.05,11.05,0,0,1,16.1.74A9.51,9.51,0,0,1,42.8,8.62c.33,1.74.48,3.52.82,5.26a9.26,9.26,0,0,0,2,3.86,8.84,8.84,0,0,0,5.23,3.2,14.59,14.59,0,0,0,2.19.29,12,12,0,0,1,7.38,2.44,10.63,10.63,0,0,1,3.77,6.26A13.1,13.1,0,0,1,64.35,31.71Zm-21.75.46c.4-6-5.07-10.29-9.77-10.47a10.48,10.48,0,1,0-1.18,20.93C37.56,43,43.07,37.78,42.6,32.17Z"/>
    </svg>
  </div>
);

const LongBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.9 21.45" width="42.9" height="21.45" className="blipBlop--h-1 blipBlop--w-2">
    <rect fill={rotation < 2200 && rotation > 200 ? fill : 'transparent'} stroke={rotation < 2200 && rotation > 200 ? stroke : 'transparent'} width="42.9" height="21.45" rx="8.1"/>
  </svg>
);

const LongBottomBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.9 42.9" width="42.9" height="42.9" className="blipBlop--h-2 blipBlop--w-2">
    <rect fill={rotation < 2600 && rotation > 1200 ? fill : 'transparent'} stroke={rotation < 2600 && rotation > 1200 ? stroke : 'transparent'} x="-0.01" y="21.45" width="42.9" height="21.45" rx="8.1" />
  </svg>
);

const LongTopBlipBlop = ({ fill, stroke, rotation }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.9 42.9" width="42.9" height="42.9" className="blipBlop--h-2 blipBlop--w-2">
    <rect fill={rotation < 3200 && rotation > 800 ? fill : 'transparent'} stroke={rotation < 3200 && rotation > 800 ? stroke : 'transparent'} className="cls-1" x="-0.01" width="42.9" height="21.45" rx="8.1"/>
  </svg>
);

const BlipBlopsTile1 = ({fill, stroke, rotation}) => {
  return (
    <div className="blipBlopsFormation__tile">
      <CircleBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
    </div>
  )
}

const BlipBlopsTile2 = ({fill, stroke, rotation}) => {
  return (
    <div className="blipBlopsFormation__tile">
      {
        rotation < 1800 ?
          <DiagonalBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
          :
          <LongTopBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      }
    </div>
  )
}

const BlipBlopsTile3 = ({fill, stroke, rotation}) => {
  return (
    <div className="blipBlopsFormation__tile">
      <FlowerBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
    </div>
  )
}

const BlipBlopsTile6 = ({fill, stroke, rotation}) => {
  return (
    <div className="blipBlopsFormation__tile">
      <LongBottomBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <SquareBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <StackedCirclesBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <LongTopBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
    </div>
  )
}

const BlipBlopsTile12 = ({fill, stroke, rotation}) => {
  return (
    <div className="blipBlopsFormation__tile">
      <DiagonalBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <CircleBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <LongBottomBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <FlowerBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <SquareBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <StackedCirclesBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <LongTopBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
      <SquareBlipBlop fill={fill} stroke={stroke} rotation={rotation} />
    </div>
  )
}

const BlipBlopsFormation = ({ fill, stroke, rotation, totalUnits }) => {

  const [tileArrays, setTileArrays] = useState({});

  useEffect(() => {
    let remaining = totalUnits;

    const arrays = {
      twelves: [],
      sixes: [],
      threes: [],
      twos: [],
      ones: []
    };

    const twelves = (totalUnits - (totalUnits % 12)) / 12;
    remaining = totalUnits % 12;
    const sixes = (remaining - (remaining % 6)) / 6;
    remaining = remaining % 6;
    const threes = (remaining - (remaining % 3)) / 3;
    remaining = remaining % 3;
    const twos = (remaining - (remaining % 2)) / 2;
    remaining = remaining % 2;
    const ones = (remaining - (remaining % 1)) / 1;
    remaining = remaining % 1;

    for (let i = 0; i < twelves; i++) {
      arrays.twelves.push(<BlipBlopsTile12 fill={fill} stroke={stroke} rotation={rotation} key={`blipBlopTwelves${i}`} />);
    }
    for (let i = 0; i < sixes; i++) {
      arrays.sixes.push(<BlipBlopsTile6 fill={fill} stroke={stroke} rotation={rotation} key={`blipBlopSixes${i}`} />);
    }
    for (let i = 0; i < threes; i++) {
      arrays.threes.push(<BlipBlopsTile3 fill={fill} stroke={stroke} rotation={rotation} key={`blipBlopThrees${i}`} />);
    }
    for (let i = 0; i < twos; i++) {
      arrays.twos.push(<BlipBlopsTile2 fill={fill} stroke={stroke} rotation={rotation} key={`blipBlopTwos${i}`} />);
    }
    for (let i = 0; i < ones; i++) {
      arrays.ones.push(<BlipBlopsTile1 fill={fill} stroke={stroke} rotation={rotation} key={`blipBlopOnes${i}`} />);
    }

    setTileArrays(arrays);

  }, [totalUnits, fill, stroke, rotation]);

  return (
    <div className="blipBlopsFormation">
      {tileArrays.twelves}
      {tileArrays.sixes}
      {tileArrays.threes}
      {tileArrays.twos}
      {tileArrays.ones}
    </div>
  )
}

export {
  CircleBlipBlop,
  DiagonalBlipBlop,
  FlowerBlipBlop,
  BlipBlopsTile12,
  FlowerBlipBlopRotating,
  LongBlipBlop,
  SquareBlipBlop,
  BlipBlopsFormation
}
