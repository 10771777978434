import '../css/App.css';
import { useState, useEffect, useCallback, Fragment } from 'react';
import * as _ from 'underscore';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { getCookieConsentValue } from 'react-cookie-consent';
import Header from './components/Header.js';
import { LandingPage, LandingPageUIBottom } from './views/LandingPage.js';
import { cockpitUrl } from './cockpit.js';
import Softer from './views/Softer.js';
import Resources from './views/Resources.js';
import Resource from './views/Resource.js';
import Live from './views/Live.js';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import MainMenu from './components/MainMenu.js';
import SocialMenu from './components/SocialMenu.js';
import CookieConsentBar from './components/CookieConsentBar.js';
import { useSwipeable } from 'react-swipeable';

window.motion = {
  x: 0,
  y: 0,
  z: 0
}

const handleDeviceMotionEvent = (e) => {
  window.motion = {
    x: e.beta,
    y: e.gamma,
    z: e.alpha
  }
  window.deviceMotionIsSupported = true;
}

const Content = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const [rotation, setRotation] = useState(1800);
  const [sideMenuIsHidden, setSideMenuIsHidden] = useState(false);
  const [headerIsHidden, setHeaderIsHidden] = useState(false);
  const [contentIsBlurred, setContentIsBlurred] = useState(false);
  const [liveData, setLiveData] = useState({});
  const [resourcesData, setResourcesData] = useState([]);
  const [landingPageData, setLandingPageData] = useState({});
  const [landingPageDataIsSet, setLandingPageDataIsSet] = useState(false);
  const [flowerDataUrl, setFlowerDataUrl] = useState('');
  const [deviceMotionIsSupported, setDeviceMotionIsSupported] = useState(false);
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);

  const handleAcceptCookies = () => {
    setCookiesAreAccepted(true);
  }

  const handleResize = () => {
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  }

  const setSpinningFlowerDataUrl = (dataUrl) => {
    setFlowerDataUrl(dataUrl);
  }

  const fetchResourcesData = useCallback(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/collections/get/resources?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        if (response.entries) {
          setResourcesData(response.entries);
        }
      })
      .catch(error => {
        console.log(error);
      })
    }, [setResourcesData]);

  const fetchLiveData = () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/classroom?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {
      setLiveData(response);
    })
    .catch(error => {
      console.log(error);
    })
  }

  const fetchLandingPageData = () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/singletons/get/homepage?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {
      setLandingPageData(response);
      setLandingPageDataIsSet(true);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    fetchLiveData();
    fetchLandingPageData();
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    let resizeTimeout;
    const setWindowResizeTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        handleResize, 200
      )
    }

    const requestDeviceMotionPermission = () => {
      DeviceMotionEvent.requestPermission()
      .then(response => {
        if (response === 'granted') {
          setDeviceMotionIsSupported(true);
          window.deviceMotionIsSupported = true;
          window.removeEventListener('click', requestDeviceMotionPermission);
          window.removeEventListener('touchstart', requestDeviceMotionPermission);
        }
      })
      .catch(console.error)
    }

    if (deviceMotionIsSupported === false) {
      if (typeof(DeviceMotionEvent) !== 'undefined') {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          // iOS 13+
          window.addEventListener('click', requestDeviceMotionPermission);
          window.addEventListener('touchstart', requestDeviceMotionPermission);
        }
      }
    }

    window.addEventListener('deviceorientation', handleDeviceMotionEvent);
    window.addEventListener('resize', setWindowResizeTimeout);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('deviceorientation', handleDeviceMotionEvent);
      window.removeEventListener('click', requestDeviceMotionPermission);
      window.removeEventListener('touchstart', requestDeviceMotionPermission);
      window.removeEventListener('resize', setWindowResizeTimeout);
    }
  }, [deviceMotionIsSupported]);

  const blurContent = () => {
    setContentIsBlurred(true);
  }

  const unblurContent = () => {
    setContentIsBlurred(false);
  }

  const showSideMenu = () => {
    setSideMenuIsHidden(false);
  }

  const hideSideMenu = () => {
    setSideMenuIsHidden(true);
  }

  const showHeader = () => {
    setHeaderIsHidden(false);
  }

  const hideHeader = () => {
    setHeaderIsHidden(true);
  }

  const [bottomUIIsHovered, setBottomUIIsHovered] = useState(false);
  const [bottomUIIsExpanded, setBottomUIIsExpanded] = useState(false);

  const handleClickBottomUIHeading = () => {
    setBottomUIIsExpanded(!bottomUIIsExpanded);
  }

  const handleClickIntoBottomUIHeading = () => {
    setBottomUIIsExpanded(true);
  }

  const handleClickOutOfBottomUIHeading = () => {
    setBottomUIIsExpanded(false);
  }

  const handleMouseEnterBottomUIHeading = () => {
    setBottomUIIsHovered(true);
  }

  const handleMouseLeaveBottomUIHeading = () => {
    setBottomUIIsHovered(false);
  }

  const updateRotation = useCallback((newRotationAmount) => {
    setRotation(newRotationAmount);
    window.rotation = newRotationAmount;
  }, [])

  const handleMouseWheel = (e) => {
    // if (props.location.pathname !== '/live') {
      const amount = e.deltaY;
      if (amount > 0) {
        if (rotation + amount >= 3600) {
          setRotation((rotation + amount) % 3600);
          window.rotation = (rotation + amount) % 3600;
        } else {
          setRotation(rotation + amount);
          window.rotation = (rotation + amount);
        }
      } else {
        if (rotation + amount < 0) {
          setRotation(3600 - (rotation + amount) % 3600)
          window.rotation = (3600 - (rotation + amount) % 3600);
        } else {
          setRotation(rotation + amount);
          window.rotation = (rotation + amount);
        }
      }
    // }
  }

  const handleSwiping = (e) => {
    // if (props.location.pathname !== '/live') {
      const amount = Math.floor((e.deltaY + e.deltaX) * 0.5);
      if (amount > 0) {
        if (rotation + amount >= 3600) {
          setRotation((rotation + amount) % 3600);
          window.rotation = (rotation + amount) % 3600;
        } else {
          setRotation(rotation + amount);
          window.rotation = (rotation + amount);
        }
      } else {
        if (rotation + amount < 0) {
          setRotation(3600 - (rotation + amount) % 3600)
          window.rotation = (3600 - (rotation + amount) % 3600);
        } else {
          setRotation(rotation + amount);
          window.rotation = (rotation + amount);
        }
      }
    // }
  }

  const swipeHandlers = useSwipeable({
    onSwiping: (e) => handleSwiping(e)
  });

  const handleMouseWheelThrottled = _.throttle(handleMouseWheel, 60);

  return (
    <div className="main">
      <div
        id="content"
        className={contentIsBlurred === true ? 'blurred' : ''}
        onWheel={handleMouseWheelThrottled}
        {...swipeHandlers}
      >
        <Header
          pathname={props.location.pathname}
          headerIsHidden={headerIsHidden}
          rotation={rotation}
          aria-hidden={props.location.pathname === '/' ? true : null}
          setSpinningFlowerDataUrl={setSpinningFlowerDataUrl}
        />
        <MainMenu
          rotation={rotation}
          sideMenuIsHidden={sideMenuIsHidden}
          windowDimensions={windowDimensions}
          pathname={props.location.pathname}
        />
        <AnimatePresence exitBeforeEnter initial={false}>
          <Routes location={props.location} key={props.location.pathname}>
            <Route
              key={`softer`}
              path="/softer"
              element={
                <Softer
                  rotation={rotation}
                  showSideMenu={showSideMenu}
                  hideSideMenu={hideSideMenu}
                  showHeader={showHeader}
                  hideHeader={hideHeader}
                  windowDimensions={windowDimensions}
                />
              }
            />
            <Route
              key={`resources`}
              path="/resources"
              element={
                <div className="resources__wrapper--main">
                  <Resources
                    windowDimensions={windowDimensions}
                    hideSideMenu={hideSideMenu}
                    showSideMenu={showSideMenu}
                    rotation={rotation}
                    resourcesData={resourcesData}
                    fetchResourcesData={fetchResourcesData}
                  />
                </div>
              }
            />
            <Route
              key={`live`}
              path="/live"
              element={
                <Live
                  windowDimensions={windowDimensions}
                  rotation={rotation}
                  updateRotation={updateRotation}
                  liveData={liveData}
                  hideSideMenu={hideSideMenu}
                  showSideMenu={showSideMenu}
                  showHeader={showHeader}
                  hideHeader={hideHeader}
                  sideMenuIsHidden={sideMenuIsHidden}
                  flowerDataUrl={flowerDataUrl}
                />
              }
            />
            <Route
              key={`privacy-policy`}
              exact
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  rotation={rotation}
                  showSideMenu={showSideMenu}
                  hideSideMenu={hideSideMenu}
                  showHeader={showHeader}
                  hideHeader={hideHeader}
                  windowDimensions={windowDimensions}
                />
              }
            />
            <Route
              path="*"
              element={
                <Fragment>
                  <LandingPageUIBottom
                    isHovered={bottomUIIsHovered}
                    isExpanded={bottomUIIsExpanded}
                    handleClick={handleClickBottomUIHeading}
                    handleMouseEnter={handleMouseEnterBottomUIHeading}
                    handleMouseLeave={handleMouseLeaveBottomUIHeading}
                    handleClickIntoBottomUIHeading={handleClickIntoBottomUIHeading}
                    handleClickOutOfBottomUIHeading={handleClickOutOfBottomUIHeading}
                    windowDimensions={windowDimensions}
                    rotation={rotation}
                    liveData={liveData}
                    landingPageData={landingPageData}
                    {...props}
                  />
                  <SocialMenu
                    windowWidth={windowDimensions.windowWidth}
                    windowHeight={windowDimensions.windowHeight}
                    rotation={rotation}
                    landingPageData={landingPageData}
                  />
                  <LandingPage
                    windowDimensions={windowDimensions}
                    rotation={rotation}
                    bottomUIIsExpanded={bottomUIIsExpanded}
                    hideSideMenu={hideSideMenu}
                    showSideMenu={showSideMenu}
                    handleClickOutOfBottomUIHeading={handleClickOutOfBottomUIHeading}
                    landingPageData={landingPageData}
                    landingPageDataIsSet={landingPageDataIsSet}
                  />
                </Fragment>
              }
            />
          </Routes>
        </AnimatePresence>
      </div>
      {
        props.location.pathname.indexOf('/resources/') === 0 &&
          <Resource
            windowDimensions={windowDimensions}
            resourcesData={resourcesData}
            blurContent={blurContent}
            unblurContent={unblurContent}
          />
      }
      {
        cookiesAreAccepted !== true &&
          <CookieConsentBar handleAcceptCookies={handleAcceptCookies} />
      }
    </div>
  )
}

const App = () => {

  useEffect(() => {
    const onLoad = () => {
      document.body.classList.add('loaded');
    }
    window.addEventListener('load', onLoad);
  }, []);

  const location = useLocation();
  const navHistory = useNavigate();

  return (
    <div className="App">
      <Content
        location={location}
        history={navHistory}
      />
    </div>
  );
}

export default App;
