import { Link } from 'react-router-dom';
import Logo from './Logo.js';
import { motion, AnimatePresence } from 'framer-motion';

const MainMenu = (props) => {

  const { sideMenuIsHidden, windowDimensions } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div className="header__wrapper">
      <AnimatePresence>
        {
          sideMenuIsHidden === false ?
            <motion.header
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.2,
                delay: 0,
                ease: "easeInOut"
              }}
              style={{
                width: windowHeight ? (windowHeight - 100) + 'px' : null,
                fontSize: windowHeight ? ((windowHeight - 80) / 16) + 'px' : null
              }}
              className="header">
              <ul className="header__list">
                <Logo rotation={props.rotation} />
                <li className="header__list__item">
                  <Link className="header__list__item__link link--ui" to="/softer">Softer</Link>
                </li>
                <Logo rotation={props.rotation} />
                <li className="header__list__item">
                  <Link className="header__list__item__link link--ui" to="/resources">Resources</Link>
                </li>
                <Logo rotation={props.rotation} />
                <li className="header__list__item">
                  <Link className="header__list__item__link link--ui" to="/live">Live</Link>
                </li>
                <Logo rotation={props.rotation} />
              </ul>
            </motion.header> : ''
        }
      </AnimatePresence>
    </div>
  )
}

export default MainMenu;